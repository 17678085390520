/**
 * @prettier
 */
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, DropDown, DropDownItem, Text } from '@pidedirecto/ui';
import { HeadphoneIcon, PhoneIcon, WhatsAppIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { AppTheme } from 'src/styles/AppTheme';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantContactInformation(): React.ReactElement | null {
    const classes = useStyles();
    const theme = useTheme();

    const contactInformationEnabled = useSelector((state) => state.app.restaurant?.contactInformationEnabled);
    const contactPhoneNumber = useSelector((state) => state.app.restaurant?.contactPhoneNumber);
    const contactWhatsAppPhoneNumber = useSelector((state) => state.app.restaurant?.contactWhatsAppPhoneNumber);

    const shouldRenderDropDown = contactPhoneNumber && contactWhatsAppPhoneNumber;

    const whatsAppUrl = `https://wa.me/${contactWhatsAppPhoneNumber}`;
    const phoneNumberUrl = `tel:${contactPhoneNumber}`;

    const goToWhatsApp = () => {
        window.open(whatsAppUrl, '_blank');
    };
    const goToPhoneNumber = () => {
        window.open(phoneNumberUrl, '_blank');
    };

    if (!contactInformationEnabled) return null;

    if (!shouldRenderDropDown)
        return (
            <Button classes={{ button: classes.iconContainer }} onClick={contactPhoneNumber ? goToPhoneNumber : goToWhatsApp}>
                <PhoneIcon color={'#333333'} title={translate('Phone icon')} />
            </Button>
        );

    return (
        <DropDown
            content={
                <div className={classes.iconContainer}>
                    <PhoneIcon color={theme.palette.primary.main} title={translate('Phone icon')} />
                </div>
            }
            variant={'icon'}
            classes={{ button: classes.dropDownButton }}
        >
            <DropDownItem classes={{ container: classes.dropDownItemContainer }} onClick={goToWhatsApp}>
                <WhatsAppIcon color={theme.palette.primary.main} size={25} title={translate('WhatsApp icon')} />
                <Text className={classes.contactText}>{translate('¡Order on WhatsApp!')}</Text>
            </DropDownItem>
            <DropDownItem classes={{ container: classes.dropDownItemContainer }} onClick={goToPhoneNumber}>
                <HeadphoneIcon color={theme.palette.primary.main} size={25} title={translate('Headphone icon')} />
                <Text className={classes.contactText}>{translate('Call the @phoneNumber', { phoneNumber: contactPhoneNumber })}</Text>
            </DropDownItem>
        </DropDown>
    );
}

const useStyles = makeStyles((theme) => ({
    dropDownItemContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 12,
    },
    contactText: {
        fontFamily: AppTheme.typography.regular,
        color: '#221F2B',
        fontSize: 12,
    },
    dropDownButton: {
        backgroundColor: 'transparent',
        margin: 0,
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 42,
        height: 42,
        padding: 8,
        borderRadius: '50%',
        background: 'white',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        cursor: 'pointer',
    },
}));
