/**
 * @prettier
 */
import { RestaurantPaymentMethodVm } from 'src/api/pidedirecto/payment/RestaurantPaymentMethodVm';
import type { GeoJsonPointVm } from 'src/api/pidedirecto/types/GeoJsonPointVm';
import { PromoCodeVm } from 'src/api/pidedirecto/types/PromoCodeVm';
import { pidedirectoApiMethodPublic } from 'src/api/pidedirectoApiMethodPublic';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { App } from 'src/constants/App';
import type { Country } from 'src/constants/Country';
import type { Currency } from 'src/constants/Currency';
import { CustomPaymentMethodIcon } from 'src/constants/CustomPaymentMethodIcons';
import type { MenuItemSize } from 'src/constants/MenuItemSize';
import type { MenuItemType } from 'src/constants/MenuItemType';
import type { ModifierType } from 'src/constants/ModifierType';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import type { ProductLayout } from 'src/constants/ProductLayout';
import { RestaurantPsp } from 'src/constants/RestaurantPsp';
import type { RestaurantType } from 'src/constants/RestaurantType';
import type { ServiceType } from 'src/constants/ServiceType';
import type { SubscriptionPlan } from 'src/constants/SubscriptionPlan';
import type { SubscriptionStatus } from 'src/constants/SubscriptionStatus';
import type { UnitOfMeasurement } from 'src/constants/UnitOfMeasurement';
import { CustomPaymentMethod, DeliveryCategory, MenuCategoryId, MenuId, MenuItemId, PlexoStoreId, RestaurantId, RestaurantMenuId, TimeZoneName } from 'src/types/Id';
import { PromotionVm } from 'src/types/PromotionVm';

export async function getAppContextApi(request: GetAppContextApiRequest): ApiSauceResponse<GetAppContextApiResponse> {
    return pidedirectoApiMethodPublic('getAppContextApi', request);
}

export type GetAppContextApiRequest = {
    urlSubdomain: string;
    urlPathname?: string;
    appVersion?: string;
};

export type GetAppContextApiResponse = {
    restaurantChain?: RestaurantChainVm;
    restaurants?: Array<RestaurantVm>;
    urlPathname?: string;
    restaurantMenu?: RestaurantMenuVm;
    promotions?: Array<PromotionVm>;
    promoCodes?: Array<PromoCodeVm>;
    restaurantNotFound?: boolean;
    redirectUrl?: string;
    quickActionMessage?: string;
    settings?: {
        whatsAppAuthenticationEnabled?: boolean;
    };
    pwa?: {
        appicon: string;
        theme_color: string;
    };
};

export type RestaurantChainVm = {
    name: string;
    imageUrl?: string;
    chainColors: Array<string>;
    backgroundImageUrl?: string;
};

export type RestaurantVm = {
    restaurantId: RestaurantId;
    restaurantMenuId?: RestaurantMenuId;
    plexoStoreId?: PlexoStoreId;
    restaurantType: RestaurantType;
    restaurantPsp?: RestaurantPsp;
    mercadoPagoPublicKey?: string;
    restaurantColors?: [string, string];
    deliveryCategories?: Array<DeliveryCategory>;
    name: string;
    street?: string;
    postalCode?: string;
    city?: string;
    location?: GeoJsonPointVm;
    phoneNumber: string;
    contactInformationEnabled?: boolean;
    contactPhoneNumber?: string;
    contactWhatsAppPhoneNumber?: string;
    logoImageUrl?: string;
    imageUrl?: string;
    imageUrlMobile?: string;
    backgroundImageUrl?: string;
    pickupStationClosingTime?: string;
    pickupStationDeliveryTime?: string;
    hours: string;
    info?: string;
    infoUrl?: string;
    promoCodeBannerEnabled?: boolean;
    comingSoon: boolean;
    temporarilyOffline: boolean;
    temporarilyWithoutDelivery?: boolean;
    country: Country;
    timeZone: TimeZoneName;
    eatHereEnabled: boolean;
    serviceType: ServiceType;
    takeAwayEnabled: boolean;
    deliveryEnabled: boolean;
    roomServiceEnabled: boolean;
    roomServiceCustomLabel?: string;
    roomServiceFixedDeliveryCost?: string;
    uberDaasDeliveryEnabled?: boolean;
    markDriverTipAsServiceFeeEnabled?: boolean;
    uberDaasOrdersWithCashEnabled?: boolean;
    hideOrderItemNotesEnabled?: boolean;
    plannedOrdersEnabled: boolean;
    cashPaymentsEnabled: boolean;
    ecommerceCashPaymentsToEatHereEnabled?: boolean;
    ecommerceCashPaymentsToTakeAwayEnabled?: boolean;
    showDriverArriveAtStoreStatusOnEcommerceEnabled?: boolean;
    hideEcommerceDriverTipEnabled?: boolean;
    hideEcommerceRestaurantPhoneNumberEnabled?: boolean;
    pideDirectoDefaultTipPercentage?: number;
    pideDirectoCustomTipPercentage?: number;
    pideDirectoTipAsPercentageEnabled?: boolean;
    pideDirectoTipEnabled?: boolean;
    restaurantServiceFeeEnabled?: boolean;
    restaurantServiceFeeRate?: number;
    minimumOrderAmount?: string;
    maximumOrderAmount?: string;
    maximumCashOrderAmount: string;
    mandatoryCashAmountEnabled?: boolean;
    webOrdersEnabled?: boolean;
    uruwareClave?: string;
    csdCertificateUploaded?: boolean;
    csdCertificateRestaurantId?: RestaurantId;
    inAppPaymentsEnabled: boolean;
    cardOnDeliveryPaymentsEnabled: boolean;
    hideDeliveryEstimatedTimeEnabled?: boolean;
    maximumOrderAmountWithoutCardValidation?: string;
    paymentLinksEnabled: boolean;
    preparationTime: number;
    hidePlannedOrders: boolean;
    externalDelivery?: boolean;
    externalDeliveryEstimatedTime?: string;
    deliveryRadiusInKm: number;
    ownFleetEnabled?: boolean;
    externalOwnFleetEnabled?: boolean;
    hideCartEnabled?: boolean;
    addTaxToTotalEnabled?: boolean;
    taxRate?: string;
    urlPathname?: string;
    urlSubdomain?: string;
    whatsAppOrdersNumber?: string;
    serviceCutOff?: boolean;
    remainingDays?: number;
    subscriptionPlan?: SubscriptionPlan;
    subscriptionStatus?: SubscriptionStatus;
    googleAnalyticsId?: string;
    facebookPixelId?: string;
    useNonGeoLocatedDeliveryAddressesEnabled?: boolean;
    ecommerceOrdersCashbackEnabled?: boolean;
    ecommerceOrdersCashbackPercentage?: string;
    paymentMethods?: Array<{
        name: CustomPaymentMethod;
        paymentMethod: PaymentMethod;
        commission?: string;
        enabled?: boolean;
        channels?: Array<App>;
        icon?: CustomPaymentMethodIcon;
    }>;
    restaurantPaymentMethods?: Array<RestaurantPaymentMethodVm>;
    limitProductsInOrder?: boolean;
    productLayout?: ProductLayout;
    hideFollowUpBannerEnabled?: boolean;
};

export type RestaurantMenuVm = {
    restaurantMenuId?: RestaurantMenuId;
    menus: Array<MenuVm>;
    currencyFormat: CurrencyFormat;
};

export type CurrencyFormat = {
    locale: string;
    currency: Currency;
    fractionDigits: number;
};

export type MenuVm = {
    menuId: MenuId;
    name: string;
    orderItemsMaximumQuantity?: string;
    categories: Array<MenuCategoryVm>;
};

export type MenuCategoryVm = {
    menuCategoryId: MenuCategoryId;
    name: string;
    open?: boolean;
    orderItemsMaximumQuantity?: string;
    menuItems: Array<MenuItemVm>;
};

export type MenuItemVm = {
    menuItemId: MenuItemId;
    name: string;
    menuItemType: MenuItemType;
    description?: string;
    contains?: string;
    price: string;
    promoPrice?: string;
    promoText?: string;
    size: MenuItemSize;
    imageUrl?: string;
    salesUnit?: UnitOfMeasurement;
    currency?: Currency;
    isSoldByWeight?: boolean;
    minimumSalesQuantity?: string;
    modifierGroups: Array<ModifierGroupVm>;
    pickupTime?: Date; // Applicable for Pick Up Station order,
    orderItemsMaximumQuantity?: string;
    menuCategoryId?: MenuCategoryId;
};

export type ModifierGroupVm = {
    name: string;
    requiredMin?: number;
    requiredMax?: number;
    freeModifiersQuantity?: number;
    modifiers: Array<ModifierVm>;
};

export type ModifierVm = {
    modifierId?: MenuItemId;
    name: string;
    price: string;
    type: ModifierType;
    subModifierGroups?: Array<SubModifierGroupVm>;
    preselected?: boolean;
    description?: string;
};

export type SubModifierGroupVm = {
    name: string;
    requiredMin?: number;
    requiredMax?: number;
    freeModifiersQuantity?: number;
    subModifiers: Array<SubModifierVm>;
};

export type SubModifierVm = {
    name: string;
    price: string;
    type: ModifierType;
};
